import React from 'react';
import { MarketplaceAPI } from 'revibe-api';
import { I18N_NAMESPACES } from 'i18n';
import { useTranslation } from 'i18n/hooks';
import { AspectRatio, FormControl, SpacedContainer } from 'revibe-ui';

import { formatPrice } from 'modules/catalog/utils/formatPrice';
import Image from 'next/image';
import { Link } from 'ui/components/Link';
import { useCart } from '../hooks/useCart';
import { Textarea } from 'ui/components/Textarea';
import { useForm } from 'react-hook-form';

type CartItemProps = {
  item: MarketplaceAPI.CartItem;
  editable?: boolean;
  details?: boolean;
};

export const CartItem = ({
  item,
  details = false,
  editable = false,
}: CartItemProps) => {
  const { removeFromCart, updateItemCartInfo, cart, cartState } = useCart();
  const { tu, t } = useTranslation('shop');

  const { register, watch } = useForm({
    defaultValues: {
      user_info: item.user_info,
    },
  });

  if (!cart) {
    return null;
  }

  return (
    <SpacedContainer className="relative">
      {cartState.isRemovingProduct && (
        <div className="absolute inset-0 h-full w-full animate-pulse bg-slate-300/50" />
      )}
      <div className="flex">
        <div className="w-24 shrink-0 sm:w-32">
          <AspectRatio ratio={3 / 4}>
            <Image
              src={item.details.photos[0]?.mini_link || ''}
              alt={item.details.name}
              className="bg-[#f3f3f3] object-cover"
              fill
            />
          </AspectRatio>
        </div>

        <div className="flex w-full flex-col gap-y-4 p-4">
          <div>
            <div className="flex h-full justify-between">
              <div className="text-sm">
                <Link
                  to={`/shop/item/${item.details.slug}`}
                  className="font-medium text-gray-700 hover:text-gray-800"
                >
                  {item.details.name}
                </Link>
                <SpacedContainer
                  centered
                  type="horizontal"
                  spacing={'small'}
                  className="w-full justify-start"
                >
                  <p className="uppercase text-gray-400">{item.size}</p>
                  {item.colors && (
                    <div className="h-3 w-3 cursor-pointer overflow-hidden rounded-full border border-slate-200">
                      <div
                        className="h-full w-full"
                        style={{ backgroundColor: item.colors }}
                      />
                    </div>
                  )}
                </SpacedContainer>
                <p className="text-gray-400">
                  {tu('quantity')}: {item.amount}
                </p>
              </div>

              <p className="ml-4 whitespace-nowrap text-right text-sm font-medium text-gray-900">
                {formatPrice(
                  (item.discounted_price || item.price) * item.amount
                )}{' '}
                &euro;{' '}
                {item.amount > 1 && (
                  <>
                    <br />
                    <span className="whitespace-nowrap text-xs font-light text-gray-400">
                      {formatPrice(item.discounted_price || item.price)} &euro;{' '}
                      {t('each')}
                    </span>
                  </>
                )}
              </p>
            </div>
          </div>

          <div className="flex w-full flex-1 items-end justify-end">
            {editable && (
              <button
                onClick={() => {
                  removeFromCart({
                    cartID: cart.id,
                    itemID: item.id,
                  });
                }}
                type="button"
                className="text-sm font-medium text-black hover:text-slate-800"
              >
                <span>
                  {item.amount === 1
                    ? tu('remove', I18N_NAMESPACES.COMMON)
                    : tu('remove-one', I18N_NAMESPACES.COMMON)}
                </span>
              </button>
            )}
          </div>
        </div>
      </div>
      {details && (
        <SpacedContainer spacing="small" className="px-4 pb-4">
          {item.customization && (
            <FormControl
              label={tu('custom-fields', 'shop')}
              className="max-w-3xl"
            >
              <span className="text-sm text-gray-500">
                {item.customization}
              </span>
            </FormControl>
          )}

          <FormControl
            label={tu('message-to-designer', 'common')}
            className="max-w-3xl"
          >
            <form>
              <Textarea
                disabled={!editable}
                placeholder={tu('size-preorder-info2')}
                {...register('user_info')}
                onBlur={() =>
                  updateItemCartInfo({
                    cartID: cart.id,
                    itemID: item.id,
                    update: {
                      user_info: watch('user_info') || undefined,
                    },
                  })
                }
              />
            </form>
          </FormControl>
        </SpacedContainer>
      )}
    </SpacedContainer>
  );
};
