import React from 'react';

import { SpacedContainer } from 'revibe-ui';

type Props = {
  icon: React.ReactNode;
  heading: string;
  text: string;
  closeButton?: React.ReactNode;
  children?: React.ReactNode;
};

export const NoData = ({
  icon,
  heading,
  text,
  closeButton,
  children,
}: Props) => {
  return (
    <SpacedContainer
      centered
      className="mx-auto h-full max-w-[512px] text-center"
    >
      {icon}
      <div>
        <p className="text-2xl font-semibold">{heading}</p>
        <p className="text-gray-500">{text}</p>
      </div>
      {children}
      {closeButton}
    </SpacedContainer>
  );
};
