import React from 'react';

import CartSvg from './cart.svg';

type Props = {
  className?: string;
  onClick?: () => void;
};

export const CartIcon = ({ className, onClick }: Props) => (
  <CartSvg className={className} onClick={onClick} />
);
