import React from 'react';

import { Textarea as RevibeUITextarea, cn } from 'revibe-ui';

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  error?: any;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, ...props }, ref) => {
    return (
      <RevibeUITextarea
        className={cn('rounded-none bg-gray-50', className)}
        {...props}
        ref={ref}
      />
    );
  }
);

Textarea.displayName = 'Textarea';

export { Textarea };
