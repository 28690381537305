import React from 'react';

import { useRouter } from 'next/router';

import { I18N_NAMESPACES } from 'i18n';
import { useTranslation } from 'i18n/hooks';
import { Button, Drawer, ScrollArea } from 'revibe-ui';
import { CHECKOUT_ROUTES } from 'routes';

import { CartItem } from 'modules/checkout/components/CartItem';
import { NoData } from 'modules/common/components/NoData';
import { formatPrice } from 'modules/catalog/utils/formatPrice';

import { CartIcon } from 'ui/icons/CartIcon';
import { useCart } from '../hooks/useCart';
import { useDataLayer } from 'modules/tracking/hooks/useDataLayer';
import { fontVariables } from 'ui/fonts/fontVariables';

export const CartPanel = () => {
  const { cart, cartModal } = useCart();
  const router = useRouter();
  const { tu } = useTranslation(I18N_NAMESPACES.SHOP);
  const dataLayer = useDataLayer();

  return (
    <Drawer
      position="right"
      onOpenChange={cartModal.close}
      open={cartModal.isOpen}
      className={fontVariables}
    >
      {cart && !cart.isEmpty ? (
        <div className="relative flex h-[100vh] flex-col justify-between overflow-y-auto">
          <h1 className="p-4 text-2xl text-black md:text-3xl lg:text-4xl">
            {tu('cart')}
          </h1>

          <ScrollArea className="h-full w-full flex-1">
            <ul className="divide-y divide-gray-200 border-y border-gray-200">
              {cart.items.map((item, i) => (
                <div key={`${item.details.id} ${item.size} ${i}`}>
                  <CartItem item={item} editable />
                </div>
              ))}
            </ul>
          </ScrollArea>
          <div className="box-border border-t border-black bg-gray-50">
            <div className="p-4">
              <dl className="space-y-4">
                <div className="flex items-center justify-between">
                  <dt className="text-base font-medium text-gray-900">
                    {tu('subtotal')}
                  </dt>
                  <dd className="ml-4 text-base font-medium text-gray-900">
                    {formatPrice(cart.subtotal.total)} &euro;
                  </dd>
                </div>
              </dl>
              <p className="mt-1 text-sm text-gray-500">
                {tu('delivery-calculated-next-step', 'shop.delivery')}
              </p>
            </div>
            <div className="p-4">
              <Button
                className="w-full"
                onClick={() => {
                  dataLayer.trackBeginCheckout(cart);
                  router.push(CHECKOUT_ROUTES.DELIVERY_CONFIGURATION);
                  cartModal.close();
                }}
              >
                {tu('go-to-checkout')}
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="h-full p-4">
          <NoData
            icon={<CartIcon className="h-12 w-12" />}
            heading={tu('cart-empty-heading')}
            text={tu('cart-empty-text')}
            closeButton={
              <Button onClick={cartModal.close}>{tu('start-shopping')}</Button>
            }
          />
        </div>
      )}
    </Drawer>
  );
};
